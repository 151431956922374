.App {
  text-align: center;
  overflow: hidden;
}
.fullscreen{
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #2e2f42db;
  z-index: 200;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: auto;
  backdrop-filter: blur(8px); /* Blur effect on elements behind */
  -webkit-backdrop-filter: blur(8px); /* Safari support */
}
.top-cover.desktop{
  background-color: rgb(0 0 0 / 87%);
  width: 15% !important;
  height: 100%;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 105;
  backdrop-filter: blur(8px); /* Blur effect on elements behind */
  -webkit-backdrop-filter: blur(8px); /* Safari support */
  padding: 15px;
}
.anchorBtn:hover{
  opacity: 0.8;
}
.background-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden; /* Prevents overflow */
  z-index: 99;
}
#fullMedia{
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 100000;
}
.background-blur{
  width: 100vw;
  height: 100vh;
  object-fit: cover;         /* Stretches image to cover */
  filter: blur(12px);         /* Applies blur effect */
  transform: scale(1.1);      /* Slightly scales the background to hide edges */
  position: fixed;         /* Positions it in the background */
  top: 0;
  left: 0;
  z-index: -1;               /* Ensures it stays behind */
}

.foreground-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: fill;
  max-width: 90vw;
  max-height: 90vh;
  height: 100%;
  border-radius: 25px;
  opacity: 0.7;
}

.fade-image {
  /* Mask gradient for fading effect */
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0));
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;

  /* For non-WebKit browsers */
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0));
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
}


.dim{
  background-color: #00000075;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.delay{
  animation-delay: 0.3s;
}
.bgcoverPre{
  width: 101%;
  height: 100vh;
  position: absolute;
  z-index: 0;
  left: 0;
  top:0;
  object-fit: cover;
}
p{
  font-size: 16px;
}
.topz{
  z-index: 101;
}
.menu{
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 900;
  position: absolute;
  right: 10%;
  top: 30%;
  font-size: 30px;
  transition: 0.04s;
}
.menu:hover{
 cursor: pointer;
 color: lightgray;
}
.blocc-cover{
  width: 125px;
  height: 125px;
  border-radius: 50%;
  margin-top: 10%;
  object-fit: cover;
}

.blocc-cover-reduced{
  height: 55px;
  margin-left: 3%;
  margin-top: 12px;
  width: 55px;
  object-fit: cover;
  border-radius: 50%;
  
}
.transition{
  transition: 0.04s;
}
.descrText-reduced{
  margin-left: 15px;
  margin-top: 28px;
  white-space: break-spaces;
  -webkit-line-clamp: 1;
  overflow: hidden;

  color: white;
  max-height: 25px;
  display: inline-block;
  text-align: left;
}

.structureContainer{
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descrText{
  width: 90%;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  white-space: pre-line;
}
.linkCard{
  width: 100%;
  border-radius: 65px;
  margin-bottom: 15px;
  justify-content: 'center';
  align-items: 'center';
  margin-left: 15px;
  margin-right: 15px;
  padding: 8px;
  transition: 0.4s;

}
.imageCard{
  border-radius: 25px;
  height: 225px;
  margin-bottom: 15px;
  width: 100%;
  object-fit: cover;
  transition: 0.4s;
  overflow: hidden;
}
.linkCard:hover{
  cursor: pointer;
  opacity: 0.6;
  transform: scale(1.01);
}
.imageCard:hover{
  cursor: pointer;
  opacity: 0.6;
  transform: scale(1.05);
  border-radius: 16px;
}
.no-margin{
  margin: 0;
}
.CardTitle{
  color: 'black';
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%
}
.descCard{
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
  white-space: pre-wrap;
}
.leftcentered{
  text-align: left;
  display: inline-block;
}

.transparent{
  opacity: 0.75 !important;
  visibility: visible;
  box-shadow: 0 6px 0 0 rgb(0 0 0 / 1%), 0 15px 32px 0 rgb(0 0 0 / 6%) !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.line{
  background-color: rgb(0 0 0 / 40%);
  /* width: 69%; */
  height: 1.3px;
  position: relative;
  top: 13.5px;
  flex-grow: 1;
}
.stacked{
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
}
.anchorBtn{
  background-color: rgb(0 0 0 / 63%);
  box-shadow: 0 6px 0 0 rgb(0 0 0 / 1%), 0 15px 32px 0 rgb(0 0 0 / 6%);
  padding: 10px;
  border-radius: 25px;
  color: white;
  margin-bottom: 5%;
}
.nostyle{
  color: transparent !important;
  text-decoration: none !important;
}
.custom-icons{
  margin-right: 20px;
  font-size: 22px;
  transition: 0.3s;
}
.custom-icons:hover{
  opacity: 0.6;
  cursor: pointer;
  transform: scale(1.25);
}
.custom-icons.inmenu {
  margin-right: 44px;
  font-size: 23px;
  color: white;
}
.custom-icons:last-child{
  margin-right: 0px;
}
.centered{
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
#shareIcon{
  
  position: absolute;
  top: 3%;
  right: 5%;
  z-index: 9999;
  background-color: #00000082;
  border-radius: 50px;
  padding: 10px;

}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  max-width: 700px;
  margin: auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.932); 
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@media (max-width: 600px) {
  .imageCard {
    border-radius: 25px;
    height: 185px;
    margin-bottom: 15px;
    object-fit: cover;
    object-position: top center;
    width: 100%;
  }
  .bgcover{
    width: 101vw;
    height: 100vh;
    position: fixed;
    z-index: 99;
    left: 0;
    object-fit: cover;

  }
  .top-cover{
    background-color: rgb(0 0 0 / 87%);
    height: 75px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 105;
    backdrop-filter: blur(8px); /* Blur effect on elements behind */
    -webkit-backdrop-filter: blur(8px); /* Safari support */
  }
  .structureContainer {
    width: 96%;
  }
  .mbl{
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
  }
}